var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("TopBar", { attrs: { app: "", "bar-type": "error" } }),
      _c(
        "v-main",
        { staticClass: "xss-error d-flex justify-center align-center" },
        [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-img", {
                staticStyle: { margin: "0 auto" },
                attrs: {
                  src: require("@/assets/images/error.svg"),
                  "max-width": "350",
                },
              }),
              _c("h1", [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
              _vm.subTitle
                ? _c("h4", [_vm._v(_vm._s(_vm.$t(_vm.subTitle)))])
                : _vm._e(),
              _vm.desc ? _c("p", [_vm._v(_vm._s(_vm.$t(_vm.desc)))]) : _vm._e(),
              _c(
                "div",
                { staticClass: "d-flex justify-center footer mt-16" },
                [
                  _c(
                    "AppButton",
                    { staticClass: "mr-4", attrs: { to: "/", block: "" } },
                    [_vm._v("Back")]
                  ),
                  _c(
                    "AppButton",
                    { attrs: { color: "secondary", to: "/", block: "" } },
                    [_vm._v("Go To Home")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }