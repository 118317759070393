<template>
  <v-app>
    <TopBar app bar-type="error" />
    <v-main class="xss-error d-flex justify-center align-center">
      <div class="text-center">
        <v-img src="@/assets/images/error.svg" max-width="350" style="margin: 0 auto"></v-img>
        <h1>{{ $t(title) }}</h1>
        <h4 v-if="subTitle">{{ $t(subTitle) }}</h4>
        <p v-if="desc">{{ $t(desc) }}</p>
        <div class="d-flex justify-center footer mt-16">
          <AppButton to="/" class="mr-4" block>Back</AppButton>
          <AppButton color="secondary" to="/" block>Go To Home</AppButton>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from '@/components/TopBar.vue';

export const CODE_MESSAGES = {
  97: {
    title: 'error.oops',
    subTitle: 'error.somethingWentWrong',
  },
};

export default {
  name: 'XssError',
  components: {
    TopBar,
  },
  computed: {
    code() {
      return this.$route.params.code;
    },
    title() {
      return CODE_MESSAGES[this.code]?.title || 'Error';
    },
    subTitle() {
      return CODE_MESSAGES[this.code]?.subTitle;
    },
    desc() {
      return CODE_MESSAGES[this.code]?.desc;
    },
  },
};
</script>

<style lang="scss" scoped>
.xss-error {
  height: 100%;

  .footer {
    max-width: 200px;
    margin: 0 auto;
  }
}
</style>

